import React, { useEffect } from "react";
import ScrollReveal from "scrollreveal";
import logo from './img/zenlablogo.png';

export const Navigation = ({ logoLink = "#page-top", navItems, showHomeLink = false }) => {
  useEffect(() => {
    ScrollReveal().reveal("#menu", { delay: 500, origin: 'top', distance: '50px' });
  }, []);

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a className="navbar-brand page-scroll" href={logoLink}>
                <img src={logo} alt="Logo" className="navbar-icon" />
                <span>ENLAB</span>
              </a>
            </li>
          </ul>
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            {showHomeLink && (
              <li>
                <a href="/" className="page-scroll">
                  Home
                </a>
              </li>
            )}
            {navItems.map((item, index) => (
              <li key={index}>
                <a href={item.href} className="page-scroll">
                  {item.text}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </nav>
  );
};
