import React, { useState, useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import ScrollReveal from 'scrollreveal';
import emailjs from 'emailjs-com';
import './ServiceEvaluation.css'; // Import the CSS file for styling

const ServiceEvaluation = () => {
  const [serviceType, setServiceType] = useState('');
  const [material, setMaterial] = useState('');
  const [color, setColor] = useState('');
  const [quantity, setQuantity] = useState('');
  const [postProcessing, setPostProcessing] = useState([]);
  const [additionalNotes, setAdditionalNotes] = useState('');
  const [captchaVerified, setCaptchaVerified] = useState(false);

  const serviceOptions = [
    {
      name: 'Resin printing',
      materials: ['Tough', 'ABS-like', 'Standard'],
      colors: ['White', 'Transparent', 'Black', 'Cyan'],
      image: './img/resin-printing.png',
    },
    {
      name: 'FFF/FDM printing',
      materials: ['ABS+', 'PLA', 'PETG', 'PA12+CF'],
      colors: ['depends on material'],
      image: '/images/fdm-printing.jpg', // Example image path
    },
    { name: '3d modelling', materials: [], colors: [], image: '' },
    { name: '3d scanning', materials: [], colors: [], image: '' },
    { name: 'laser engraving', materials: [], colors: [], image: '' },
    { name: 'laser cutting', materials: [], colors: [], image: '' },
  ];

  useEffect(() => {
    ScrollReveal().reveal('.form-group, .postprocessing-options, .content-wrapper, .additional-notes, .recaptcha, .send-button, .col-md-4 ', {
      delay: 0,
      duration: 0, // Setze duration auf 0, um die Animation zu entfernen
      reset: true,
      distance: '0px', // Setze distance auf 0px, um die Animation zu entfernen
      origin: 'bottom',
      easing: 'linear', // Linear ist optional, da keine Bewegung stattfindet
    });
  }, []);  

  const handleServiceChange = (e) => {
    const selectedService = e.target.value;
    setServiceType(selectedService);
    setMaterial('');
    setColor('');
    setQuantity('');
    setPostProcessing([]);
  };

  const handleMaterialChange = (e) => {
    setMaterial(e.target.value);
    setColor('');
  };

  const handlePostProcessingChange = (value) => {
    if (postProcessing.includes(value)) {
      setPostProcessing((prev) => prev.filter((item) => item !== value));
    } else {
      setPostProcessing((prev) => [...prev, value]);
    }
  };

  const handleQuantityChange = (e) => {
    setQuantity(e.target.value);
  };

  const handleAdditionalNotesChange = (e) => {
    setAdditionalNotes(e.target.value);
  };

  const handleCaptchaVerify = () => {
    setCaptchaVerified(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (captchaVerified) {
      const templateParams = {
        serviceType,
        material,
        color,
        quantity,
        postProcessing: postProcessing.join(', '),
        additionalNotes,
      };

      emailjs
        .send('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', templateParams, 'YOUR_PUBLIC_KEY')
        .then(
          (result) => {
            console.log(result.text);
            alert('Form successfully submitted');
            setServiceType('');
            setMaterial('');
            setColor('');
            setQuantity('');
            setPostProcessing([]);
            setAdditionalNotes('');
            setCaptchaVerified(false);
          },
          (error) => {
            console.log(error.text);
          }
        );
    } else {
      alert('Please complete the reCAPTCHA verification.');
    }
  };

  const getImageUrl = () => {
    const selectedOption = serviceOptions.find((option) => option.name === serviceType);
    return selectedOption ? selectedOption.image : '';
  };

  const getAltImageUrl = () => {
    return './img/resin-printing.png'; // Default alternative image path
  };

  const verticalLineStyle = {
    borderLeft: '3px solid white',
    height: '140%',
    position: 'absolute',
    left: '10px',
    top: '0',
  };

  return (
    <section id="service-evaluation">
      <div className="container">
        <div className="row">
          <div className="col-md-8">
            <div className="section-title">
              <h2>Service Evaluation</h2>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="serviceType">Select Service</label>
                <select
                  id="serviceType"
                  className="form-control"
                  value={serviceType}
                  onChange={handleServiceChange}
                  required
                >
                  <option value="">Select Service</option>
                  {serviceOptions.map((option, index) => (
                    <option key={index} value={option.name}>
                      {option.name}
                    </option>
                  ))}
                </select>
              </div>

              {serviceType && (
                <>
                  <div className="form-group">
                    <label htmlFor="material">Select Material</label>
                    <select
                      id="material"
                      className="form-control"
                      value={material}
                      onChange={handleMaterialChange}
                      required
                    >
                      <option value="">Select Material</option>
                      {serviceOptions
                        .find((option) => option.name === serviceType)
                        .materials.map((mat, index) => (
                          <option key={index} value={mat}>
                            {mat}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div className="form-group">
                    <label htmlFor="color">Select Color</label>
                    <select
                      id="color"
                      className="form-control"
                      value={color}
                      onChange={(e) => setColor(e.target.value)}
                      required
                    >
                      <option value="">Select Color</option>
                      {serviceOptions
                        .find((option) => option.name === serviceType)
                        .colors.map((col, index) => (
                          <option key={index} value={col}>
                            {col}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div className="form-group">
                    <label></label>
                    <div className="postprocessing-options">
                      <div className="checkbox-wrapper">
                        <input
                          type="checkbox"
                          id="painting"
                          checked={postProcessing.includes('painting')}
                          onChange={() => handlePostProcessingChange('painting')}
                        />
                        <label htmlFor="painting">Painting</label>
                      </div>
                      <div className="checkbox-wrapper">
                        <input
                          type="checkbox"
                          id="polishing"
                          checked={postProcessing.includes('polishing')}
                          onChange={() => handlePostProcessingChange('polishing')}
                        />
                        <label htmlFor="polishing">Polishing</label>
                      </div>
                      <div className="checkbox-wrapper">
                        <input
                          type="checkbox"
                          id="additional-engraving"
                          checked={postProcessing.includes('additional engraving')}
                          onChange={() => handlePostProcessingChange('additional engraving')}
                        />
                        <label htmlFor="additional-engraving">Additional Engraving</label>
                      </div>
                    </div>
                  </div>

                  <div className="form-group additional-notes">
                    <label htmlFor="additionalNotes">Additional Notes</label>
                    <textarea
                      id="additionalNotes"
                      className="form-control"
                      value={additionalNotes}
                      onChange={handleAdditionalNotesChange}
                      rows="4"
                      placeholder="Enter any additional notes here..."
                    />
                  </div>

                  <div className="form-group recaptcha">
                    <ReCAPTCHA
                      sitekey="YOUR_RECAPTCHA_SITE_KEY"
                      onChange={handleCaptchaVerify}
                    />
                  </div>

                  {captchaVerified && (
                    <button type="submit" className="btn btn-custom btn-lg mt-3 float-right send-button">
                      Send
                    </button>
                  )}
                </>
              )}
            </form>
          </div>

          <div className="col-md-4 position-relative">
            <div style={verticalLineStyle}></div>
            <img
              src={getImageUrl() || getAltImageUrl()}
              alt="Service Image"
              className="img-fluid mt-3 ml-3"
              style={{ marginTop: '180px', marginLeft: '30px' }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceEvaluation;
