import React, { useEffect, Suspense } from "react";
import { Canvas, useLoader } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";
import ScrollReveal from "scrollreveal";

const Model = ({ path }) => {
  const geometry = useLoader(STLLoader, path);
  return (
    <mesh geometry={geometry} scale={[0.1, 0.1, 0.1]} position={[0, 0, 0]}>
      <meshStandardMaterial color="black" />
    </mesh>
  );
};

const ModelViewer = ({ path }) => {
  useEffect(() => {
    ScrollReveal().reveal("#model-viewer-container", {
      delay: 500,
      origin: 'bottom',
      distance: '50px'
    });
  }, []);

  return (
    <div
      id="model-viewer-container"
      style={{ background: 'linear-gradient(to right, #000000 0%, #000000ad 100%)', width: "1850px", height: "500px", position: "relative" }}
    >
      <Canvas
        style={{ background: 'rgba(0, 0, 0, 0.2)', width: "45%", height: "100%", position: "absolute", top: 0, left: 500 }}
        camera={{ position: [0, -250, 100] }}
      >
        <Suspense fallback={null}>
          <ambientLight intensity={0.5} />
          <pointLight position={[0, 0, 10]} />
          <Model path={path} />
          <OrbitControls enableZoom={false} />
        </Suspense>
      </Canvas>
    </div>
  );
};

export default ModelViewer;
