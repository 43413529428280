import React, { useEffect } from "react";
import ScrollReveal from "scrollreveal";
import Typical from 'react-typical';

export const Header = (props) => {
  useEffect(() => {
    ScrollReveal().reveal(".intro-text", { delay: 500, origin: 'top', distance: '50px' });
  }, []);

  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
                <h1 className="wave-hover">
                  <Typical
                    steps={[
                      props.data ? props.data.title : "",
                      1000
                    ]}
                    loop={1}
                    wrapper="span"
                  />
                </h1>
                <p>{props.data ? props.data.paragraph : ""}</p>
                <a
                  href="#services"
                  className="btn btn-custom btn-lg page-scroll learn-more"
                >
                  <div className="circle">
                    <span className="icon arrow"></span>
                  </div>
                  <span className="button-text">Learn More</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
