import React, { useEffect, useState } from "react";
import ScrollReveal from "scrollreveal";
import './Css/contact.css'; // Importiere die CSS-Datei

const initialState = {
  name: "",
  email: "",
  message: "",
};

export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState);
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    ScrollReveal().reveal("#contact .container", { delay: 500, origin: 'bottom', distance: '50px' });

    // Deaktivieren des Scrollens, wenn das Modal aktiv ist
    if (showConfirmation) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto'; // Sicherstellen, dass das Scrollen zurückgesetzt wird, wenn der Effekt aufgeräumt wird
    };
  }, [showConfirmation]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const clearState = () => setState({ ...initialState });


  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: ""
  });
  
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(name, email, message);
  
    // Simuliere das Versenden der E-Mail für lokale Tests
    // emailjs
    //   .sendForm("YOUR_SERVICE_ID", "YOUR_TEMPLATE_ID", e.target, "YOUR_PUBLIC_KEY")
    //   .then(
    //     (result) => {
    //       console.log(result.text);
    //       clearState();
    //       setFormData({ name, email, message });
    //       setShowConfirmation(true);
    //     },
    //     (error) => {
    //       console.log(error.text);
    //     }
    //   );
  
    // Für lokale Tests direkt die Bestätigungsdaten setzen
    console.log("Formular lokal übermittelt, Bestätigung anzeigen...");
    clearState();
    setFormData({ name, email, message });
    setShowConfirmation(true);
  };

  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
  };

  const handleOverlayClick = (e) => {
    // Schließen des Modals, wenn außerhalb des `#confirmation-modal` geklickt wird
    if (e.target.id === 'confirmation-overlay') {
      handleCloseConfirmation();
    }
  };

  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Get In Touch</h2>
                <p>
                  Please fill out the form below to send us an email and we will
                  get back to you as soon as possible.
                </p>
              </div>
              <form name="sentMessage" validate onSubmit={handleSubmit}>
                <div className="input-container ic1">
                  <input
                    type="text"
                    id="name"
                    name="name"
                    className="input"
                    placeholder=" "
                    required
                    onChange={handleChange}
                  />
                  <div className="cut"></div>
                  <label htmlFor="name" className="placeholder">Name</label>
                </div>
                <div className="input-container ic2">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className="input"
                    placeholder=" "
                    required
                    onChange={handleChange}
                  />
                  <div className="cut cut-short"></div>
                  <label htmlFor="email" className="placeholder">Email</label>
                </div>
                <div className="input-container ic2">
                  <textarea
                    name="message"
                    id="message"
                    className="input"
                    rows="4"
                    placeholder=" "
                    required
                    onChange={handleChange}
                  ></textarea>
                  <div className="cut"></div>
                  <label htmlFor="message" className="placeholder">Message</label>
                </div>
                <button type="submit" className="submit">Submit</button>
              </form>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Address
                </span>
                {props.data ? props.data.address : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Phone
                </span>{" "}
                {props.data ? props.data.phone : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                {props.data ? props.data.email : "loading"}
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : "/"}>
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.twitter : "/"}>
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.youtube : "/"}>
                      <i className="fa fa-youtube"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; 2024. Design by{" "}
            <a href="" rel="nofollow">
              High
            </a>
          </p>
        </div>
      </div>

      {showConfirmation && (
        <div id="confirmation-overlay" onClick={handleOverlayClick}>
          <div id="confirmation-modal" onClick={(e) => e.stopPropagation()}>
            <button className="close-button" onClick={handleCloseConfirmation}>×</button>
            <div className="card">
              <div className="icon"></div>
              <h1>Thank you for reaching out!</h1>
              <p>Our support team will get back to you within 24 hours.</p>
            </div>
            <div className="card">
              <ul>
                <li>
                  <span>Name</span>
                  <span>{formData.name}</span> {/* Anzeigen des Namens */}
                </li>
                <li>
                  <span>Email</span>
                  <span>{formData.email}</span> {/* Anzeigen der E-Mail-Adresse */}
                </li>
                <li>
                  <span>Message</span>
                  <span>{formData.message}</span> {/* Anzeigen der Nachricht */}
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
