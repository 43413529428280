import React, { useState, useEffect } from "react";
import ScrollReveal from "scrollreveal";
import { Image } from "./image";

export const Gallery = ({ data = [] }) => {  // Default value for data
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [modalImage, setModalImage] = useState(null);

  useEffect(() => {
    ScrollReveal().reveal("#portfolio .portfolio-item", { delay: 500, origin: 'bottom', distance: '50px' });
  }, []);

  const handleFilterClick = (filter) => {
    setSelectedFilter(filter);
  };

  const filteredData = selectedFilter === "All"
    ? data
    : data.filter(item => item.filter === selectedFilter);

  const openModal = (image) => {
    setModalImage(image);
  };

  const closeModal = () => {
    setModalImage(null);
  };

  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Gallery</h2>
          <p>BLBLBABABKLABAKB</p>
        </div>

        {/* Filter Buttons */}
        <div className="filters">
          {data.length > 0 ? (
            ["All", ...new Set(data.map(item => item.filter))].map((filter, i) => (
              <button
                key={i}
                onClick={() => handleFilterClick(filter)}
                className={`filter-button ${selectedFilter === filter ? "active" : ""}`}
              >
                {filter}
              </button>
            ))
          ) : (
            <p>No filters available</p>
          )}
        </div>

        <div className="row">
          <div className="portfolio-items">
            {filteredData.length > 0 ? (
              filteredData.map((d, i) => (
                <div
                  key={`${d.title}-${i}`}
                  className="col-sm-6 col-md-4 col-lg-4 portfolio-item"
                  onClick={() => openModal(d.largeImage)}
                >
                  <Image
                    title={d.title}
                    largeImage={d.largeImage}
                    smallImage={d.smallImage}
                  />
                </div>
              ))
            ) : (
              <p>No items to display</p>
            )}
          </div>
        </div>
      </div>

      {/* Modal for large image */}
      {modalImage && (
        <div className="modal" onClick={closeModal}>
          <span className="close" onClick={closeModal}>&times;</span>
          <img className="modal-content" src={modalImage} alt="Large version" />
        </div>
      )}
    </div>
  );
};
