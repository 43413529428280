import React, { useEffect } from "react";
import Lottie from "react-lottie";
import animationData from "../assets/printer-animation.json";
import ScrollReveal from "scrollreveal";

const defaultOptions = {
  loop: true,
  autoplay: false,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

export const Print3D = () => {
  useEffect(() => {
    ScrollReveal().reveal("#print .print", { delay: 500, origin: 'bottom', distance: '50px' });
  }, []);

  return (
    <div id="print" className="print">
      <div className="text-center space-y-4">
        <h1 className="text-2xl font-bold">
          Hear comes the 3D Model!!!
        </h1>
        <p>Eazy</p>
        <div className="flex gap-2 justify-center">
          <button>Learn More</button>
          <button>Library</button>
        </div>
        <Lottie options={defaultOptions} height={400} width={400} />
      </div>
    </div>
  );
};
