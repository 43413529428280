import React, { useEffect, useState } from "react";
import ScrollReveal from "scrollreveal";
import "./Css/service.css"; // Import the styles for services
import service1 from "../assets/service/1.png";
import service2 from "../assets/service/2.png";
import service3 from "../assets/service/3.png";
import service4 from "../assets/service/4.png";
import service5 from "../assets/service/5.png";
import service6 from "../assets/service/6.png";

export const Services = (props) => {
  useEffect(() => {
    ScrollReveal().reveal("#services .row", { delay: 500, origin: 'bottom', distance: '50px' });
  }, []);

  const services = [
    { id: 1, img: service1, title: "Service 1", text: "Description for service 1" },
    { id: 2, img: service2, title: "Service 2", text: "Description for service 2" },
    { id: 3, img: service3, title: "Service 3", text: "Description for service 3" },
    { id: 4, img: service4, title: "Service 4", text: "Description for service 4" },
    { id: 5, img: service5, title: "Service 5", text: "Description for service 5" },
    { id: 6, img: service6, title: "Service 6", text: "Description for service 6" },
  ];

  const [activeService, setActiveService] = useState(null);

  const handleClick = (id) => {
    setActiveService(activeService === id ? null : id);
  };

  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
            <h2>Our Services</h2>
            <p>
              BLBALBALBALABLABLABLABLB
            </p>
        </div>
        <div className="wrapper1">
          <div className="items1">
            {services.map(service => (
              <div
                key={service.id}
                className={`item1 ${activeService === service.id ? "active" : ""}`}
                tabIndex="0"
                style={{ backgroundImage: `url(${service.img})` }}
                onClick={() => handleClick(service.id)}
              >
                {activeService === service.id && (
                  <div className="content">
                    <h3>{service.title}</h3>
                    <p>{service.text}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
