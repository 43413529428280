import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Gallery } from "./components/gallery";
import { Contact } from "./components/contact";
import ServiceEvaluation from './components/ServiceEvaluation';
import NewPage from "./components/NewPage";
import ScrollToTop from "./components/ScrollToTop";
import Typical from 'react-typical';
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  const homeNavItems = [
    { href: "#services", text: "Services" },
    { href: "#features", text: "Features" },  
    { href: "#about", text: "O nas" },  
    { href: "#portfolio", text: "Gallery" },
    { href: "#service-evaluation", text: "Service Evaluation" },
    { href: "#contact", text: "Contact" },
  ];

  const newPageNavItems = [
    { href: "#print", text: "Roof" },
    { href: "#contact", text: "Contact" }
  ];

  return (
    <Router>
      <ScrollToTop />
      <div>
        <Routes>
          <Route path="/" element={
            <>
              <Navigation logoLink="#page-top" navItems={homeNavItems} />
              <Header data={landingPageData.Header} />
              <Services data={landingPageData.Services} />
              <Features data={landingPageData.Features} />
              <About data={landingPageData.About} />
              <Gallery data={landingPageData.Gallery} />
              <ServiceEvaluation />
              <Contact data={landingPageData.Contact} />

            </>
          } />
          <Route path="/newpage" element={
            <>
              <Navigation logoLink="/" navItems={newPageNavItems} showHomeLink={true} />
              <NewPage />
            </>
          } />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
