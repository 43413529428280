import React, { useEffect } from "react";
import ScrollReveal from "scrollreveal";
import { Link } from "react-router-dom";
import "./Css/features.css";

export const Features = (props) => {
  useEffect(() => {
    ScrollReveal().reveal("#features .container", { delay: 500, origin: 'bottom', distance: '50px' });
    ScrollReveal().reveal("#features h2", { delay: 300, origin: 'top', distance: '30px' });
    ScrollReveal().reveal("#features p", { delay: 400, origin: 'bottom', distance: '30px' });
    ScrollReveal().reveal("#features .col-xs-6", { delay: 600, interval: 100, origin: 'bottom', distance: '20px' });
  }, []);

  return (
    <div id="features" className="text-center">
        <div className="col-md-10 col-md-offset-1 section-title1">
          <h2>Features</h2>
        </div>
          <section className="container">
            <div className="category_container">
              <Link to="/newpage" className="content1">
                <img src="./img/zenlablogo.png" className="professio_image" alt="Profession" />
                <img src="./img/zenlablogo.png" className="profile_image" alt="Profile" />
                <div className="profile_detail">
                  <span>John Doe</span>
                  <p>UI/UX Designer + Graphic Designer</p>
                </div>

                <div className="wrapper">			
                  <div className="profile_quote">
                    <p>"UI is the saddle, the stirrups, & the reins. UX is the feeling you get being able to ride the horse."</p>
                  </div>
                </div>
              </Link>
              <Link to="/seite2" className="content1">
                <img src="assets/Photographer.jpg" className="profession_image" alt="Profession" />
                <img src="assets/Prince Akashi.jpg" className="profile_image" alt="Profile" />
                <div className="profile_detail">
                  <span>Prince Akashi</span>
                  <p>Photographer + Model</p>
                </div>
                <div className="wrapper">				
                  <div className="profile_quote">
                    <p>"If you see something that moves you, and then snap it, you keep a moment."</p>
                  </div>
                </div>
              </Link>
              <Link to="/seite3" className="content1">
                <img src="assets/Fashion Designer.jpg" className="profession_image" alt="Profession" />
                <img src="assets/Rafaella Mendes.jpg" className="profile_image" alt="Profile" />
                <div className="profile_detail">
                  <span>Rafaella Mendes</span>
                  <p>Fashion Designer + Model</p>
                </div>
                <div className="wrapper">				
                  <div className="profile_quote">
                    <p>"My mission in life is not merely to survive, but to thrive and to do so with some passion, some compassion, some humor, and some style."</p>
                  </div>
                </div>
              </Link>
            </div>
          </section>
        </div>
  );
};
