import React, { useState, useEffect } from "react";
import { Print3D } from "./print3d.jsx";
import ModelViewer from "./ModelViewer";
import { Contact } from "./contact";
import JsonData from "../data/data.json";

const NewPage = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <Print3D />
      <ModelViewer path="/models/cart_frame.stl" />
      <Contact />
    </div>
  );
};

export default NewPage;
